const sliders = document.querySelectorAll('[data-super-slider]')

for (const slider of sliders) {
    const track = slider.querySelector('[data-super-slider-track]')
    const buttonPrev = slider.querySelector('[data-slide-prev]')
    const buttonNext = slider.querySelector('[data-slide-next]')
    const dots = slider.querySelectorAll('[data-slide-to]')
    const sliderItems = slider.querySelectorAll('[data-super-slide]')

    // initialize dot indication
    let activeIndex = 0
    setActive(activeIndex)

    if (buttonPrev && buttonNext) {
        // scroll one element to left
        buttonPrev.addEventListener('click', () => {
            scrollElements(-1)
        })
        // scroll one element to right
        buttonNext.addEventListener('click', () => {
            scrollElements(1)
        })
    }

    // scroll to targeted element
    if (dots.length > 0) {
        dots.forEach((dot, index) => {
            dot.addEventListener('click', () => {
                scrollElements(index - activeIndex)
            })
        })
    }

    // detect scrolling from buttons, dots or gesture to set snapped item to active
    track.addEventListener('scrollsnapchange', (e) => {
        const xCoordinate = e.snapTargetInline.offsetLeft
        setActive(parseFloat(e.snapTargetInline.getAttribute('data-index')))
    })

    // draggable functionality
    let isDown = false
    let startX
    let scrollLeft

    track.addEventListener('mousedown', (e) => {
        isDown = true
        track.classList.add('active')
        startX = e.pageX - track.offsetLeft
        scrollLeft = track.scrollLeft
        track.style.scrollSnapType = 'none'
    })

    track.addEventListener('mouseleave', () => {
        isDown = false
        track.classList.remove('active')
        track.style.scrollSnapType = 'x mandatory'
    })

    track.addEventListener('mouseup', () => {
        isDown = false
        track.classList.remove('active')
        track.style.scrollSnapType = 'x mandatory'
    })

    track.addEventListener('mousemove', (e) => {
        if (!isDown) return
        e.preventDefault()
        const x = e.pageX - track.offsetLeft
        const walk = (x - startX)
        track.scrollLeft = scrollLeft - walk
    })

    function scrollElements(elementsVec) {
        const targetIndex = activeIndex + elementsVec

        if(targetIndex < 0 || targetIndex >= sliderItems.length) {
            return
        }

        track.scrollTo({
            left: sliderItems[targetIndex].offsetLeft, behavior: "smooth"
        })
    }

    function setActive(index) {
        if(index < 0 || index >= sliderItems.length) {
            return
        }
        if (dots.length > 0) dots[activeIndex].querySelector('div').classList.add('hidden')
        activeIndex = index
        if (dots.length > 0) dots[activeIndex].querySelector('div').classList.remove('hidden')
    }
}
