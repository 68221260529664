window.addEventListener('load', () => {
    const checkboxContainers = document.querySelectorAll('.statamic-form-input');
    checkboxContainers.forEach((checkboxContainer) => {
        const checkbox = checkboxContainer.querySelector('input');
        const customCheckbox = checkboxContainer.querySelector('span');

        customCheckbox.addEventListener('click', () => {
            checkbox.checked = !checkbox.checked;
        })
    })
});
