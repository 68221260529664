import gsap from './gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'


window.addEventListener("load", (event) => {
    const privacyButton = document.querySelector('#privacy-button')
    ScrollTrigger.create({
        start: 0,
        end: 'max',
        onUpdate: self => {
            const scrolledY = self.scroll();
            if (scrolledY > 0) {
                gsap.to(privacyButton, { x: -privacyButton.offsetHeight, duration: 0.3, ease: "power2.out" });
            } else {
                gsap.to(privacyButton, { x: 0, duration: 0.3, ease: "power2.out" });
            }
        }
    });
});

