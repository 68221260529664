const forms = document.querySelectorAll('[data-statamic-form]');

window.addEventListener('load', () => {
    forms.forEach((form) => {
        const pageUrlField = form.querySelector('input[id="page_url"]');

        if (pageUrlField) {
            pageUrlField.value = window.location.href;
        }
    });
})

