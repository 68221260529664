import gsap from './gsap'

const container = document.querySelector('#sticky-nav')
const page = document.querySelector('#page')

if (container) {
    const logos = container.querySelectorAll('[data-logo]')
    const buttons = container.querySelector('[data-buttons]')

    logos.forEach(logo => {
        gsap.to(logo, {
            opacity: 1,
            marginLeft: 0,
            visibility: 'visible',
            scrollTrigger: {
                trigger: container,
                pinnedContainer: page,
                start: 'top top',
                toggleActions: 'restart none none reverse',
            },
        })
    })

    gsap.to(buttons, {
        opacity: 1,
        marginRight: 0,
        visibility: 'visible',
        scrollTrigger: {
            trigger: container,
            pinnedContainer: page,
            start: 'top top',
            toggleActions: 'restart none none reverse',
        },
    })
}

// animate active anchor
document.addEventListener('DOMContentLoaded', function () {
    const links = document.querySelectorAll('#sticky-nav a');

    //console.log(`Found ${links.length} links in #sticky-nav`);

    const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.2
    };

    const observerCallback = (entries) => {
        entries.forEach(entry => {
            const activeAnchorId = entry.target.id;
            const link = document.querySelector(`#sticky-nav a[href="#${activeAnchorId}"]`);
            if (entry.isIntersecting) {
                if (link) {
                    animateAddClass(link, 'underline-active');
                    const snapId = link.dataset.index > 0 ? link.dataset.index - 1 : link.dataset.index;
                    links[snapId].scrollIntoView({ block: 'nearest', inline: 'start', behavior: 'smooth' });
                    console.log(links[snapId])
                }
            } else {
                if (link) {
                    animateRemoveClass(link, 'underline-active');
                }
            }
        });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    links.forEach((link, index) => {
        link.dataset.index = index.toString();
        const targetId = link.getAttribute('href').substring(1); // Remove the '#' from the href
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            //console.log(`Observing anchor: ${targetId}`);
            observer.observe(targetElement);
        }
    });
});

// Function to animate the application of a CSS class
function animateAddClass(element, className) {
    element.parentElement.classList.add(className);
    gsap.to(element, {
        duration: 0.3,
        scaleX: 1.01,
        onComplete: () => {

        }
    });
}

// Function to animate the removal of a CSS class
function animateRemoveClass(element, className) {
    element.parentElement.classList.remove(className);
    gsap.to(element, {
        duration: 0.3,
        scaleX: 1.01,
    });
}
